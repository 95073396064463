






































import Vue from 'vue'
import Component from 'vue-class-component'
import ChangePasswordPopup from '@/components/common/ChangePasswordPopup.vue'
import EditUserPopup from '@/components/common/EditUserPopup.vue'
import ChangePhotoPopup from '@/components/common/ChangePhotoPopup.vue'

@Component({
  components: {
    ChangePasswordPopup,
    EditUserPopup,
    ChangePhotoPopup
  }
})
export default class Profile extends Vue {
  public stripeBalance: number = 0

  changeImage (image: string) {
    this.$store.dispatch('updateProfile', { id: this.$store.state.userData.id, user: { image: image } }).then(() => {
      this.$notify({
        text: 'Profile Picture Updated Successfully!',
        type: 'success'
      })
    })
  }

  getStripeBalance () {
    this.$http.get('dashboard/get-stripe-balance').then((response:any) => {
      this.stripeBalance = response.data.data.available[0]['amount'] / 100
    })
  }

  created () {
    this.getStripeBalance()
  }
}
