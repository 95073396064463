var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "green-border-box" },
    [
      _c("div", { staticClass: "top-part in-padd white-bg" }, [
        _c("div", { staticClass: "profile-photo" }, [
          _c("img", {
            staticClass: "profile-photo-img green-border-box",
            attrs: {
              src: _vm.$store.state.userData.image + "?fit=crop&w=150&h=150"
            },
            on: {
              error: function($event) {
                return _vm.$common.getDefaultImage("user")
              }
            }
          }),
          _vm._m(0)
        ]),
        _c("div", { staticClass: "afterImage" }, [
          _c("div", { staticClass: "vertical-center-transform" }, [
            _c("strong", [_vm._v(_vm._s(_vm.$store.state.userData.name))]),
            _c(
              "button",
              {
                staticClass: "btn-positive btn-small",
                attrs: {
                  "data-target": "#edit-user-popup",
                  "data-toggle": "modal"
                }
              },
              [_vm._v("EDIT")]
            )
          ])
        ])
      ]),
      _c("div", [
        _c("div", { staticClass: "col-xs-12 col-md-3 part" }, [
          _c("label", [_vm._v("Email")]),
          _c(
            "span",
            {
              directives: [
                {
                  name: "autosize",
                  rawName: "v-autosize",
                  value: 26,
                  expression: "26"
                }
              ],
              attrs: { title: _vm.$store.state.userData.email }
            },
            [_vm._v(_vm._s(_vm.$store.state.userData.email))]
          )
        ]),
        _c("div", { staticClass: "col-xs-12 col-sm-3 col-md-3 part" }, [
          _c("label", [_vm._v("Mobile")]),
          _c("span", [
            _vm._v(
              _vm._s(_vm._f("phone")(_vm.$store.state.userData.mobile_number))
            )
          ])
        ]),
        _vm._m(1),
        _c("div", { staticClass: "col-xs-12 col-sm-3 col-md-3 part" }, [
          _c("label", [_vm._v("Stripe Account Balance")]),
          _c("span", [_vm._v("$" + _vm._s(_vm.stripeBalance))])
        ]),
        _c("div", { staticClass: "clearfix" })
      ]),
      _c("ChangePasswordPopup", {
        attrs: {
          popupId: "change-password-popup",
          userId: _vm.$store.state.userData.id
        }
      }),
      _c("EditUserPopup", {
        attrs: {
          popupId: "edit-user-popup",
          userId: _vm.$store.state.userData.id
        }
      }),
      _c("ChangePhotoPopup", {
        attrs: {
          popupId: "change-photo-popup",
          userId: _vm.$store.state.userData.id,
          oldImage: _vm.$store.state.userData.image
        },
        on: { changeImage: _vm.changeImage }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      {
        staticClass: "green-border-box",
        attrs: {
          "data-target": "#change-photo-popup",
          "data-toggle": "modal",
          href: "javascript:;"
        }
      },
      [_c("img", { attrs: { src: "/img/profile/editprofile.png" } })]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-xs-12 col-sm-3 col-md-3 part" }, [
      _c("label", [_vm._v("Password")]),
      _c("span", [
        _c(
          "button",
          {
            staticClass: "btn-positive btn-small",
            attrs: {
              "data-target": "#change-password-popup",
              "data-toggle": "modal"
            }
          },
          [_vm._v("CHANGE")]
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }